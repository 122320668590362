import { Card, Button, InputNumber, message } from "antd"
import SearchList from "../../../components/SearchList"
import { ablityIsShowEnum, renderEnum, zeroOneColor, ablityPriceTypeEnum } from "../../../common/consts"
import P from "../../../components/P"
import { Link } from "react-router-dom"
import { divide, checkPermission } from "../../../utils/utils"
import { post } from "../../../common/request"
import { useState } from "react"

export default function AblityTest() {
  const [reload, setReload] = useState(false)

  const tableColumnList = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "套题名称",
      dataIndex: "title",
    },
    {
      title: "是否显示",
      dataIndex: "isShow",
      render: v => renderEnum(v, ablityIsShowEnum, zeroOneColor),
    },
    {
      title: "排序值",
      dataIndex: "sn",
      render: (v, row) => {
        const ch = event => {
          const v = event.target.value
          console.log(v)
          const postData = {
            ...(row),
            sn: v,
          }
          post("/ability/exercises/saveOrUpdate", postData).then(() => {
            message.success("操作成功")
            setReload(v => !v)
          })
        }
        return (
          <InputNumber
            disabled={!checkPermission("sys:ability:edit")}
            placeholder="回车生效"
            value={v}
            onPressEnter={ch}
            precision={0}
          />
        )
      }
    },
    {
      title: "答题时间/分钟",
      dataIndex: "mins",
    },
    {
      title: "价格配置",
      dataIndex: "priceConfig",
      render: obj => {
        if (obj?.type == ablityPriceTypeEnum.免费) {
          return "免费"
        } else if (obj?.type == ablityPriceTypeEnum.付费) {
          return `原价:${divide(obj.originalPrice, 100)}；现价:${divide(obj.price, 100)}；VIP价格:${divide(obj.vipPrice, 100)}`
        }
        return "-"
      }
    },
    {
      title: "创建时间",
      dataIndex: "gmtCreate",
    },
    {
      title: "操作",
      dataIndex: "id",
      render: v => {
        return (
          <div>
            <P
              code="sys:ability:edit"
              type="link"
            >
              <Link to={`/ablityTest/list/detail/${v}`}>套题详情</Link>
            </P>
            <Button type="link">
              <Link to={`/ablityTest/list/${v}/finishUserList`}>做题用户</Link>
            </Button>
          </div>
        )
      },
    }
  ]

  return (
    <Card>
      <SearchList
        url="/ability/exercises/page"
        method="get"
        tableColumns={tableColumnList}
        needReload={reload}
      >
        <P
          code="sys:ability:edit"
          type="primary"
          style={{ marginBottom: 15 }}
        >
          <Link to="/ablityTest/list/new">新增套题</Link>
        </P>
      </SearchList>
    </Card>
  )
}