import { Card, message } from "antd"
import SearchList from "../../../components/SearchList"
import { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { obj2oplist, submitStatusEnum, reportStatusEnum, renderEnum, zeroOneColor } from "../../../common/consts"
import P from "../../../components/P"
import apiRequest from "../../../common/request"
import fetch from "isomorphic-fetch"
import Cookies from "js-cookie"

const convertFunc = obj => {
  if (Array.isArray(obj.city) && obj.city.length) {
    obj.city = obj.city[1]
  } else {
    obj.city = ""
  }
  return obj
}

export default function FinishUserList(props) {
  const examId = props.match?.params?.examId
  const [defaultSearchData, setDefaultSearchData] = useState({})
  const [selectUserIds, setSelectUserIds] = useState([])
  const [provinceData, setProvinceData] = useState([])
  const downloading = useRef(false)

  const uiSchema = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "用户名",
      dataIndex: "userName",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
    },
    {
      title: "答题时间",
      dataIndex: "gmtCreate",
    },
    {
      title: "试题数量",
      dataIndex: "questionNum",
    },
    {
      title: "交卷状态",
      dataIndex: "status",
      render: v => renderEnum(v, submitStatusEnum, zeroOneColor)
    },
    {
      title: "是否生成报告",
      dataIndex: "commentStatus",
      render: v => renderEnum(v, reportStatusEnum, zeroOneColor)
    },
    {
      title: "收集信息",
      render: (_, row) => {
        return (
          <div>
            <div>{row.name || '-'}</div>
            <div>{row.province}{row.city || '-'}</div>
            <div>{row.school || '-'}</div>
          </div>
        )
      }
    },
    // {
    //   title: "点评数量",
    //   dataIndex: "commentNum",
    // },
    {
      title: "操作",
      dataIndex: "exercisesId",
      render: (v, record) => {
        return (
          <div>
            <Link style={{ marginRight: 10 }} to={`/ablityTest/list/${v}/finishUserList/userDetail/${record.userId}?userSubmitId=${record.id}`}>查看详情</Link>
            {/* <Link to={`/ablityTest/list/${v}/finishUserList/userDetail/${record.userId}/report`}>测评报告</Link> */}
          </div>
        )
      }
    }
  ]

  const searchSchemaList = [
    {
      label: "手机号",
      key: "mobile",
      type: "input",
    },
    {
      label: "交卷状态",
      key: "status",
      type: "select",
      oplist: obj2oplist(submitStatusEnum, Number),
    },
    {
      label: "是否生成报告",
      key: "commentState",
      type: "select",
      oplist: obj2oplist(reportStatusEnum, Number),
    },
    {
      label: "城市",
      key: "city",
      type: "cascader",
      oplist: provinceData,
      fieldNames: {
        name: "label",
        value: "label",
      }
    },
    {
      label: "学校",
      key: "school",
      type: "input",
    }
  ]

  // 导出所有学生报告的时候触发
  const exportStuReport = () => {
    const postData = {
      examineId: examId,
    }
    if (selectUserIds.length) {
      postData.ids = selectUserIds
    }
    if (downloading.current) {
      return
    }
    downloading.current = true
    const hideLoading = message.loading('下载中', 0)
    const options = {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      credentials: 'include'
    }
    options.headers['Sin-Access-Token'] = Cookies.get('accessToken')
    options.headers['Sin-UserAgent-Sign'] = 'SintureGK-WEB'
    options.headers['Sin-Client-Type'] = 3
    let responseType
    fetch("/gk/ops/ability/exercises/report/gen", options).then(res => {
      const contentType = res.headers.get('Content-Type')
      if (contentType.includes('application/json')) {
        responseType = "json"
        return res.json()
      } else if (contentType.includes('application/octet-stream')) {
        responseType = "blob"
        return res.blob()
      } else {
        throw new Error('Unsupported content type: ' + contentType)
      }      
    }).then(blob => {
      if (responseType === "json") {
        if (blob.status == 2) {
          message.error(blob.errorMes || "数据错误")
          return
        }
      } else if (responseType === "blob") {
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blob)
        downloadLink.download = `${Date.now()}_${examId}.zip` // 下载的文件名
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        URL.revokeObjectURL(downloadLink.href)
      }
    }).finally(() => {
      downloading.current = false
      hideLoading()
    })
  }

  const batchExportStuReport = () => {
    if (!selectUserIds.length) {
      message.warning("请先选择学员")
      return
    }
    exportStuReport()
  }

  const uniqueKey = "id"

  useEffect(() => {
    setDefaultSearchData({ exerciseId: examId })
  }, [examId])

  useEffect(() => {
    let config = {
      method: 'GET',
      credentials: 'include',
      headers: {
          'Accept': 'application/json'
      }
    };
    apiRequest("/api/config/cascadeTree", config).then(res => {
      setProvinceData(res?.obj || [])
    })
  }, [])

  return (
    <Card>
      {
        defaultSearchData.exerciseId ? (
          <SearchList
            rowKey={uniqueKey}
            url="/ability/exercises/user"
            tableColumns={uiSchema}
            outSearchPostData={defaultSearchData}
            searchSchema={searchSchemaList}
            useCache={true}
            tableRowSelection={{
              selectedRowKeys: selectUserIds,
              getCheckboxProps: (record) => {
                return {
                  disabled: record.commentStatus != 1,
                }
              },
              onSelect: (record, selected) => {
                const currentOrderArr = [...selectUserIds]
                if (selected) {
                  // 选中，入栈
                  currentOrderArr.push(record[uniqueKey])
                } else {
                  // 取消选中，出栈
                  const idx = currentOrderArr.findIndex(id => id === record[uniqueKey])
                  if (idx !== -1) {
                    currentOrderArr.splice(idx, 1)
                  }
                }
                setSelectUserIds(currentOrderArr)
              },
              onSelectAll: (selected, selectedRows, changeRows) => {
                let current2 = [...selectUserIds]
                if (selected) {
                  // 全部选中操作
                  current2 = current2.concat(...changeRows.map(r => r[uniqueKey]))
                } else {
                  // 取消全部选中操作
                  for (const obj of changeRows) {
                    const idx = current2.findIndex(id => id === obj[uniqueKey])
                    if (idx !== -1) {
                      current2.splice(idx, 1)
                    }
                  }
                }
                setSelectUserIds(current2)
              }
            }}
            convertPostDataFunc={convertFunc}
          >
            <P code="report:gen:code" onClick={exportStuReport} type="primary" style={{ marginBottom: 20, marginRight: 15 }}>快捷导出所有学员报告</P>
            <P code="report:gen:code" onClick={batchExportStuReport} type="primary" style={{ marginBottom: 20 }}>批量导出指定学员报告</P>
          </SearchList>
        ) : null
      }
    </Card>
  )
}