/**
 * 用户能力测评报告组件
 */

import { Card, Row, Col, Form, message, Button, Popconfirm } from "antd"
import { get, post } from "../../common/request"
import { useState, useEffect } from "react"
import FormList from "../../components/FormItem"
import P from "../../components/P"
import UseTempLateList from "../../components/UseTempLateList"
import PropTypes from "prop-types"
import { checkPermission } from "../../utils/utils"

export default function UserReport(props) {
  const userId = props.userId
  const exerciseId = props.exerciseId

  const [formInstance] = Form.useForm()

  const [reportDetail, setReportDetail] = useState({})
  const [loadingReportDetail, setLoadingReportDetail] = useState(false)

  const formListConfig = [
    {
      label: "报告内容",
      key: "reportContent",
      type: "richtext",
      required: true,
      height: 450,
    }
  ]

  const saveHandler = async () => {
    const formValues = await formInstance.validateFields()
    const postData = {
      ...(reportDetail || {}),
      ...formValues,
      userId,
      exerciseId,
    }
    return post("/ability/exercises/report/edit", postData).then(() => {
      message.success("操作成功")
      if (props.successCb) {
        props.successCb()
      }
    })
  }

  const returnCardTitle = () => {
    const styleObj = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }
    return (
      <div style={styleObj}>
        <span>测评报告内容</span>
        <UseTempLateList
          onChange={(_, option) => {
            formInstance.setFieldValue("reportContent", option?.content)
          }}
        />
      </div>
    )
  }

  useEffect(() => {
    if (!userId || !exerciseId) {
      return
    }
    setLoadingReportDetail(true)
    get(`/ability/exercises/report?userId=${userId}&exerciseId=${exerciseId}`).then(res => {
      setReportDetail(res || {})
      formInstance.setFieldsValue(res)
    }).finally(() => {
      setLoadingReportDetail(false)
    })
  }, [userId, exerciseId])

  const labelSpan = 2

  return (
    <Card title={returnCardTitle()} loading={loadingReportDetail}>
      <Form form={formInstance} labelCol={{ span: labelSpan }} wrapperCol={{ span: 24 - labelSpan }}>
        <FormList
          span={24}
          list={formListConfig}
        />
      </Form>
      <Row>
        <Col offset={labelSpan}>
          {
            checkPermission("ability:report:edit") ? (
              <Popconfirm title="确认提交吗" onConfirm={saveHandler}>
                <Button type="primary">提交</Button>
              </Popconfirm>
            ) : null
          }
          {
            props.cancelCb ? (
              <Button style={{ marginLeft: 15 }} onClick={props.cancelCb}>取消</Button>
            ) : null
          }
        </Col>
      </Row>
    </Card>
  )
}

UserReport.propTypes = {
  exerciseId: PropTypes.string, // 能力测评ID
  userId: PropTypes.string, // 用户uid
  successCb: PropTypes.func,
  cancelCb: PropTypes.func,
}