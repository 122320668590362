import { Card, Form, Row, Col, Button, message } from "antd"
import { useState, useEffect } from "react"
import { get, post } from "../../../common/request"
import { ablityIsShowEnum, obj2oplist, ablityNotShow, ablityPriceTypeEnum, collectInfoEnum } from "../../../common/consts"
import PickQuestionList from "./comp/pickQuestionList/index"
import FormList from "../../../components/FormItem"
import AblityPriceConfig, { priceConfigValidator } from "../../../components/AblityPriceConfig"
import { multiply, divide } from "../../../utils/utils"

export default function(props) {
  const id = props.match?.params?.id
  const [formInstance] = Form.useForm()
  const [questionDetail, setQuestionDetail] = useState({})
  const [loadingDetail, setLoadingDetail] = useState(false)

  const width = 500
  const formListConfig = [
    {
      label: "标题",
      key: "title",
      type: "input",
      required: true,
      w: width,
    },
    {
      label: "是否显示",
      key: "isShow",
      type: "radio",
      oplist: obj2oplist(ablityIsShowEnum, Number),
      required: true,
      initV: ablityNotShow,
    },
    {
      label: "排序值",
      key: "sn",
      type: "numberInput",
      required: true,
      w: 150,
      min: Number.MIN_SAFE_INTEGER,
    },
    {
      label: "是否收集用户信息",
      key: "collectInfo",
      type: "radio",
      oplist: obj2oplist(collectInfoEnum, Number),
      required: true,
    },
    {
      label: "答题时间/分钟",
      key: "mins",
      type: "numberInput",
      required: true,
      w: width,
    },
    {
      label: "描述",
      key: "descInfo",
      type: "textArea", 
      required: true,
      w: width,
    },
    {
      label: "价格配置",
      key: "priceConfig",
      type: "custom",
      required: true,
      render: () => <AblityPriceConfig />,
      rules: [{ validator: priceConfigValidator }],
      initV: {
        type: ablityPriceTypeEnum.付费,
        originalPrice: null,
        price: null,
        vipPrice: null,
      }
    },
    {
      label: "已勾选题目",
      key: "questionIds",
      type: "custom",
      render: v => (
        <PickQuestionList
          questionDetailList={questionDetail?.questions || []}
          updateQuestionDetailList={newArr => {
            const newObj = {
              ...questionDetail,
              questions: newArr,
            }
            setQuestionDetail(newObj)
          }}
        />
      ),
      required: true,
    }
  ]

  const saveHandler = async () => {
    let values = {}
    try {
      values = await formInstance.validateFields()
    } catch (err) {
      message.error("请检查表单必填项")
      return
    }
    const postData = {
      ...questionDetail,
      ...(values || {}),
    }
    if (postData.priceConfig.type == ablityPriceTypeEnum.付费) {
      // 如果是付费的话，接口是需要以分为单位的，传参需要改成分
      postData.priceConfig.originalPrice = multiply(postData.priceConfig.originalPrice, 100)
      postData.priceConfig.price = multiply(postData.priceConfig.price, 100)
      postData.priceConfig.vipPrice = multiply(postData.priceConfig.vipPrice, 100)
    }
    delete postData.questions
    post("/ability/exercises/saveOrUpdate", postData).then(() => {
      message.success("操作成功")
      props.history.goBack()
    })
  }

  const returnCardTitle = () => {
    // eslint-disable-next-line
    if (location.href.includes("/ablityTest/list/new")) {
      return "新增能力测试套题"
    }
    return "能力测试套题详情"
  }

  useEffect(() => {
    if (!id) {
      return
    }
    setLoadingDetail(true)
    get(`/ability/exercises/detail?id=${id}`).then(res => {
      // 接口返回的是以分为单位的，转化成元
      if (res?.priceConfig?.type == ablityPriceTypeEnum.付费) {
        res.priceConfig.originalPrice = divide(res.priceConfig.originalPrice, 100)
        res.priceConfig.price = divide(res.priceConfig.price, 100)
        res.priceConfig.vipPrice = divide(res.priceConfig.vipPrice, 100)
      }
      formInstance.setFieldsValue(res)
      setQuestionDetail(res)
    }).finally(() => {
      setLoadingDetail(false)
    })
  }, [id])

  return (
    <Card title={returnCardTitle()} loading={loadingDetail}>
      <Form form={formInstance} labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
        <FormList
          span={24}
          list={formListConfig}
        />
      </Form>
      <Row>
        <Col offset={3}>
          <Button style={{ marginTop: 20 }} onClick={saveHandler} type="primary">提交</Button>
        </Col>
      </Row>
    </Card>
  )
}