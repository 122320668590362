import React from 'react';
import { Radio, InputNumber, Form } from 'antd';
import { ablityPriceTypeEnum } from "../../common/consts";
import { multiply, divide } from "../../utils/utils"

const PriceConfig = ({ value = null, onChange }) => {
  // 初始化值，确保 value 始终为合法对象
  const currentValue = value || {};

  // 处理值变更并调用 onChange
  const triggerChange = (changedValues) => {
    if (onChange) {
      if (changedValues.type == ablityPriceTypeEnum.免费) {
        onChange({ ...changedValues })
        return
      }
      onChange({
        ...currentValue,
        ...changedValues,
      });
    }
  };

  const inputStyle = { width: 100 }

  // 乘 100
  const toApi = v => {
    if (typeof v != "number") {
      return null
    }

    const result = multiply(v, 100)
    if (Number.isNaN(result)) {
      return null
    }
    return result
  }

  // 除100
  const toUi = v => {
    if (typeof v != "number") {
      return null
    }

    const answer = divide(v, 100)
    if (Number.isNaN(answer)) {
      return null
    }
    return answer
  }

  return (
    <Form layout="inline">
      {/* 类型选择 */}
      <Form.Item label="">
        <Radio.Group
          value={currentValue.type}
          onChange={(e) => triggerChange({ type: e.target.value })}
        >
          <Radio value={ablityPriceTypeEnum.免费}>免费</Radio>
          <Radio value={ablityPriceTypeEnum.付费}>付费</Radio>
        </Radio.Group>
      </Form.Item>

      {/* 当类型为付费时，显示价格相关输入框 */}
      {currentValue.type === 1 && (
        <>
          <Form.Item label="原价">
            <InputNumber
              min={0}
              precision={2}
              value={currentValue.originalPrice}
              onChange={(value) => triggerChange({ originalPrice: value })}
              style={inputStyle}
              placeholder="请输入"
            />
          </Form.Item>
          <Form.Item label="现价">
            <InputNumber
              min={0}
              precision={2}
              value={currentValue.price}
              onChange={(value) => triggerChange({ price: value })}
              style={inputStyle}
              placeholder="请输入"
            />
          </Form.Item>
          <Form.Item label="VIP价格">
            <InputNumber
              min={0}
              precision={2}
              value={currentValue.vipPrice}
              onChange={(value) => triggerChange({ vipPrice: value })}
              style={inputStyle}
              placeholder="请输入"
            />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export const priceConfigValidator = (rule, value, callback) => {
  if (!value) {
    callback()
    return
  }
  if (value.type == ablityPriceTypeEnum.付费) {
    // 表示付费，那么几个价格字段必须填
    const tips = []
    if (value.originalPrice == undefined) {
      tips.push("原价")
    }
    if (value.price == undefined) {
      tips.push("现价")
    }
    if (value.vipPrice == undefined) {
      tips.push("VIP价格")
    }

    if (tips.length) {
      callback("请填写" + tips.join("、"))
      return
    }
  }
  callback()
}

export default PriceConfig;
