import { Card, Image, message, Popconfirm, Button, notification } from 'antd'
import SearchList from '../../../components/SearchList'
import P from '../../../components/P'
import { isVipEnum, renderEnum, zeroOneColor, notvip, obj2oplist, userIdentityEnum, isvip } from '../../../common/consts'
import { Link } from 'react-router-dom'
import { post, get } from '../../../common/request'
import { useRef, useState } from 'react'
import ModalForm from '../../../components/ModalForm';

export default function AppUserList(props) {
  const [reload, setReload] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [tagUserObj, setTagUserObj] = useState({})
  const [showUserTypeModal, setShowUserTypeModal] = useState(false)
  const [selectUserIds, setSelectUserIds] = useState([])

  const submitRef = useRef(false)
  const searchSchemaList = [
    {
      label: '手机号',
      key: 'mobile',
      type: 'input',
    },
    {
      label: '是否VIP',
      key: 'vipStatus',
      type: 'select',
      oplist: obj2oplist(isVipEnum),
    },
    {
      label: "登录时间范围",
      key: "times",
      type: "rangePicker",
    },
    {
      label: "注册时间范围",
      key: "times2",
      type: "rangePicker",
    },
    {
      label: "用户类型",
      key: "identity",
      type: "select",
      oplist: obj2oplist(userIdentityEnum, Number)
    }
  ]

  const clickSetUserTypeBtn = (uobj) => {
    setTagUserObj(uobj)
    setShowUserTypeModal(true)
  }

  // 把不存在的用户给提示出来
  const unExistUserToast = res => {
    let arr = []
    if (Array.isArray(res)) {
      arr = res
    }
    if (arr.length) {
      notification.open({
        message: '注：下列手机号未注册过平台',
        description: arr.join(','),
        placement: 'topRight', // 弹窗的位置
        duration: 0, // 弹窗显示的时间（秒）
      });
    }
  }

  const uiSchema = [
    {
      title: "用户ID",
      dataIndex: "id",
    },
    {
      title: "手机号",
      dataIndex: "mobile",
    },
    {
      title: "注册时间",
      dataIndex: "gmtCreate",
    },
    {
      title: "上次登录时间",
      dataIndex: "lastLoginTime",
    },
    {
      title: "昵称",
      dataIndex: "nickName",
    },
    {
      title: "头像",
      dataIndex: "avatar",
      width: 100,
      render: v => <Image src={v} />,
    },
    {
      title: "用户类型",
      dataIndex: 'identity',
      render: v => userIdentityEnum[v],
    },
    {
      title: "是否VIP",
      dataIndex: "isVip",
      render: v => renderEnum(v, isVipEnum, zeroOneColor)
    },
    {
      title: "操作",
      dataIndex: "id",
      width: 80,
      render: (v, rowObj) => {
        const setVip = () => {
          if (!rowObj.mobile) {
            message.warning('用户还未授权手机号，无法标记为VIP')
            return
          }
          const requestData = {
            mobiles: [rowObj?.mobile],
          }
          if (rowObj.isVip == notvip) {
            requestData.status = 1
          } else {
            requestData.status = 0
          }
          if (submitRef.current) {
            message.warning('提交中')
            return
          }
          submitRef.current = true
          post('/user/vip', requestData).then(res => {
            unExistUserToast(res)
            message.success('操作成功')
            setReload(!reload)
          }).finally(() => {
            submitRef.current = false
          })
        }

        return (
          <div>
            {/* <P code="ability:report:edit" type="link">
              <Link to={`/userManage/appUserList/${v}/report`}>测评报告</Link>
            </P> */}
            <P code="user:identity:set" type="link">
              <Button onClick={() => clickSetUserTypeBtn(rowObj)} type="link">标记用户类型</Button>
            </P>
            <P code="user:vip:set" type="link">
              <Popconfirm
                title={rowObj.isVip == notvip ? "确定标记该学员为VIP？" : "确定取消VIP？" }
                onConfirm={setVip}
              >
                { rowObj.isVip == notvip ? '标记VIP' : '取消VIP' }
              </Popconfirm>
            </P>
          </div>
        )
      }
    }
  ]

  const batchHandler = () => {
    setShowModal(true)
  }

  const modalConfirmHandler = values => {
    const mobileStr = values?.mobiles || ""
    let mobiles = mobileStr.split(",")
    mobiles = mobiles.map(str => {
      return str.trim()
    })
    mobiles = mobiles.filter(str => !!str)
    if (!mobiles.length) {
      message.warning("至少填写一个手机号")
      return
    }
    const postData = {
      ...(values),
      mobiles
    }
    // postData.status = 1
    return post("/user/vip", postData).then(res => {
      unExistUserToast(res)
      message.success("操作成功")
      setReload(!reload)
    })
  }

  const modalFormList = [
    {
      label: "手机号数据",
      type: "textArea",
      key: "mobiles",
      required: true,
      placeholder: "多个手机号用英文下的,分割"
    },
    {
      label: '会员标识',
      type: 'radio',
      key: 'status',
      required: true,
      oplist: obj2oplist(isVipEnum, Number),
      // initialValue={v?.initV}
      initV: isvip,
    },
    {
      label: '用户类型',
      type: 'radio',
      key: 'identity',
      required: true,
      oplist: obj2oplist(userIdentityEnum),
    }
  ]

  const userTypeModalFormList = [
    {
      label: "用户类型",
      type: "radio",
      key: "identity",
      required: true,
      oplist: obj2oplist(userIdentityEnum, Number),
    }
  ]

  const userTypeModalConfirmHandler = values => {
    let userIdArr
    if (selectUserIds.length) {
      userIdArr = selectUserIds
    } else {
      userIdArr = [tagUserObj.id]
    }
    const url = `/user/identity/${values.identity}/${userIdArr.join(',')}`
    return get(url).then(res => {
      message.success("操作成功")
      setReload(!reload)
    })
  }

  const batchFlagHandler = () => {
    if (!selectUserIds.length) {
      message.warning("请先至少选中一个用户")
      return
    }
    setShowUserTypeModal(true)
  }

  return (
    <Card>
      <SearchList
        rowKey="id"
        url="/user/page"
        tableColumns={uiSchema}
        searchSchema={searchSchemaList}
        useCache={true}
        needReload={reload}
        timesKey="times"
        startTimeKey="startTime"
        endTimeKey="endTime"
        startTimeFormatStr="YYYY-MM-DD 00:00:00"
        endTimeFormatStr="YYYY-MM-DD 23:59:59"
        extraConvertTimes={[
          {
            key: "times2",
            startTimeKey: "createStartTime",
            endTimeKey: "createEndTime",
            startTimeFormatStr: "YYYY-MM-DD 00:00:00",
            endTimeFormatStr: "YYYY-MM-DD 23:59:59",
          }
        ]}
        tableRowSelection={{
          selectedRowKeys: selectUserIds,
          onSelect: (record, selected) => {
            const currentOrderArr = [...selectUserIds]
            if (selected) {
              // 选中，入栈
              currentOrderArr.push(record.id)
            } else {
              // 取消选中，出栈
              const idx = currentOrderArr.findIndex(id => id === record.id)
              if (idx !== -1) {
                currentOrderArr.splice(idx, 1)
              }
            }
            setSelectUserIds(currentOrderArr)
          },
          onSelectAll: (selected, selectedRows, changeRows) => {
            let current2 = [...selectUserIds]
            if (selected) {
              // 全部选中操作
              current2 = current2.concat(...changeRows.map(r => r.id))
            } else {
              // 取消全部选中操作
              for (const obj of changeRows) {
                const idx = current2.findIndex(id => id === obj.id)
                if (idx !== -1) {
                  current2.splice(idx, 1)
                }
              }
            }
            setSelectUserIds(current2)
          }
          // onChange: (keys, rows) => {
          //   setSelectUserIds(keys)
          // }
        }}
      >
        <P style={{ marginBottom: 20, marginRight: 20 }} onClick={batchHandler} code="user:vip:set" type="primary">手机号导入配置VIP及用户类型</P>
        <P
          code="user:identity:set"
          type="primary"
          style={{ marginBottom: 20 }}
          onClick={batchFlagHandler}
        >
          批量标记用户类型
        </P>
      </SearchList>

      <ModalForm
        title="手机号导入配置VIP及用户类型"
        visible={showModal}
        onOk={modalConfirmHandler}
        onCancel={() => setShowModal(false)}
        formList={modalFormList}
      />

      <ModalForm
        title="标记用户类型"
        visible={showUserTypeModal}
        onOk={userTypeModalConfirmHandler}
        onCancel={() => {
          setTagUserObj({})
          setSelectUserIds([])
          setShowUserTypeModal(false)
        }}
        formList={userTypeModalFormList}
        initValue={tagUserObj}
      />
    </Card>
  )
}