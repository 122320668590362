export const OSS_PUB_GK_COURSE = "gk-course"; // 视频的私有仓库
export const OSS_PUB_GK_NEWS = "gk-news"; // 视频的公共仓库
export const PUB_TO_ENDPOINT = {
  [OSS_PUB_GK_COURSE]: "oss-cn-beijing.aliyuncs.com",
  [OSS_PUB_GK_NEWS]: "oss-cn-beijing.aliyuncs.com",
}; // OSS库的endPoint

export const success_color = '#52c41a';
export const fail_color = '#f5222d';
export const normal_color = '#666';
export const warn_color = '#fa8c16';

export const WORK_FILTER_STATUS_ALL = "all";
export const WORK_FILTER_STATUS_DONE = "done";
export const WORK_FILTER_STATUS_NOT = "not";

export const examineType_real = 0;
export const examineType_flexable = 1;

export const obj2oplist = (obj, valueParser) => {
  const keys = Object.keys(obj)
  let answer = []
  for (let k of keys) {
    const item = {
      name: obj[k],
      value: k,
    }
    if (typeof valueParser === "function") {
      item.value = valueParser(item.value)
    }
    answer.push(item)
  }
  return answer
}

export const bannerShowState = 1
export const bannerStateEnum = {
  0: "不展示",
  [bannerShowState]: "展示",
}
export const zeroOneColor = {
  0: fail_color,
  1: success_color,
}

export const articleStatusEnum = {
  0: "未发布",
  1: "已发布",
}

export const correctStatusEnum = {
  0: "未批改",
  1: "已经批改",
}

export const ablityNotShow = 0
export const ablityIsShowEnum = {
  [ablityNotShow]: "不展示",
  1: "展示",
}

export const collectInfoEnum = {
  1: "收集",
  0: "不收集"
}

export const payStatusEnum = {
  0: "未付款",
  1: "已付款",
}

export const recommendedQuesitonNotShowEnum = 0
export const recommendedQuestionIsShowEnum = {
  0: "不展示",
  1: "展示",
}

export const notvip = 0
export const isvip = 1
export const isVipEnum = {
  [notvip]: "否",
  [isvip]: "是",
}

export const submitStatusEnum = {
  0: "未交卷",
  1: "已交卷",
}

export const reportStatusEnum = {
  0: "未生成",
  1: "已生成",
}

export const renderEnum = (val, enumObj, colorObj) => {
  const cn = enumObj[val] || '-';
  let color = colorObj?.[val];
  if (!colorObj || !color) {
    return cn;
  }
  return (
    <div className="whyColorfulRenderContainerWhy">
      <div className="colorfulDotCls" style={{ backgroundColor: color }} />
      <div>{cn}</div>
    </div>
  );
};

export const examTypeEnum = {
  0: "真题",
  1: "必练题目",
  2: "能力测试",
}

export const ablityPriceTypeEnum = {
  免费: 0,
  付费: 1,
}

export const userIdentityEnum = {
  0: "普通用户",
  1: "员工",
  2: "内部学员",
}