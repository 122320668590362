/**
 * 渲染input形式的搜索条件
 */
import { Form, Cascader } from 'antd';
import PropTypes from "prop-types";

export const CascaderSchemaUI = ({ schema }) => {
  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  return (
    <Form.Item
      label={schema.label}
      name={schema.key}
      initialValue={schema.initialValue || undefined}
      style={{ marginBottom: "10px" }}
    >
      <Cascader
        style={{ width: schema.width || 150 }}
        options={schema.oplist || []}
        showSearch={{
          filter
        }}
        placeholder={schema?.placeholder || `请选择${schema?.label}`}
        fieldNames={{ label: schema?.fieldNames.name || "name", value: schema?.fieldNames.value || "value", children: schema?.fieldNames.children || "children" }}
        autoComplete="new-password"
      />
    </Form.Item>
  );
};

CascaderSchemaUI.propTypes = {
  schema: PropTypes.object, // 数据建模
};
